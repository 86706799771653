<template>
  <div>
    <v-layout wrap pb-16 pa-lg-8 >
      <v-flex xs12 pt-lg-5>
        <span style="font-family: JostBold; font-size: 25px">OUR VALUES</span>
      </v-flex>
      <v-flex xs2 sm2 md2 text-center>
        <v-layout wrap>
          <v-flex xs8>
            <v-progress-linear
              value="100"
              height="2"
              color="#1B6DC1"
            ></v-progress-linear>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout wrap justify-start pt-5>
        <v-flex xs12 sm6 md4 pa-2 pa-xs-2 pa-sm-2 pa-md-5 pa-lg-5 pa-xl-5 v-for="(item, i) in items" :key="i">
          <v-layout wrap justify-center>
            <v-flex xs12 text-left>
              <v-icon size="40" :color="hover ? 'white' : '#1B6DC1'">{{
                item.icon
              }}</v-icon>
            </v-flex>
            <v-flex xs12 pt-3>
              <span
                style="font-family: OutfitBold; font-size: 20px; color: black"
                >{{ item.name }}</span
              >
            </v-flex>
            <v-flex xs12 pt-3 text-justify>
              <span
                style="
                font-family:JostRegular;
                  color:black; "
                >{{ item.content }}</span
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          icon: "mdi-lightbulb-variant",
          name: "Ownership",
          content:
            "Everyone in our community feels a sense of belongingness as an integral part of the institution than separate entities at work.",
        },
        
        {
          icon: "mdi-hand-heart",
          name: "Unity",
          content:
            "We value the oneness among ourselves rather than anything else. We believe in the synergy of our individual capacities that add up to the core strength.",
        },
        {
          icon: "mdi-handshake",
          name: "Respect and fairness",
          content:
            "We help each other grow as self respecting individuals by maintaining polity and fair treatment. Respect and fairness cultivate dignity, fostering growth through equitable interactions.  ",
        },
        {
          icon: "mdi-ballot",
          name: "Passion for progress",
          content:
            "We find joy in actualizing our potentials and helping others to do the same. We believe in staying dynamic and growing to expand so that we can reach out to more people who need our assistance.",
        },
        {
          icon: "mdi-piggy-bank",
          name: "Symbiotic growth",
          content:
            "We believe in mutual benefit. We grow ourselves by benefitting the individuals who approach us. Symbiotic growth fosters a harmonious ecosystem where each other's progress and potential.",
        },
        {
          icon: "mdi-lightbulb-on",
          name: "Innovative Learning",
          content:
            "We embrace new ideas and technologies to create dynamic, engaging learning experiences. Innovation in education empowers students and educators to explore their full potential.",
        },
      ],
    };
  },
};
</script>