<template>
  <div>
    <!-- lg screen only -->
    <div>
      <v-layout>
        <v-flex>
          <servicesHome />
        </v-flex>
      </v-layout>

      <v-card elevation="0" v-if="itemsCarousel.length>0" class="hidden-xs-only">
        <div class="carousel-container">
          <OwlCarousel :nav="false" :dots="false" :loop="true" :autoplay="true" :autoplaySpeed="1000"
            :autoplayTimeout="5000" :animateOut="'fadeOut'" :responsive="owlResponsive">
            <template v-for="(list, l) in itemsCarousel">
              <v-layout wrap justify-center v-if="list" :key="l">
                <v-flex>
                  <v-img :height="$vuetify.breakpoint.name == 'xs'
                    ? '00'
                    : $vuetify.breakpoint.name == 'sm'
                      ? '500px'
                      : $vuetify.breakpoint.name == 'md'
                        ? '480px'
                        : '100%'" :src="list.src" eager></v-img>
                </v-flex>
              </v-layout>
            </template>
          </OwlCarousel>
          <v-layout wrap justify-center class="fixed-overlay-content ">
            <v-flex xs10 sm10 md11 lg11 pt-16 pt-sm-0 pt-md-0>
              <v-layout wrap justify-center>
                <v-flex xs12 sm12 md10 lg10 pt-0 pt-md-0 data-aos="zoom-in-up" data-aos-ease="ease"
                  data-aos-duration="1500" data-aos-delay="500">
                  <span style="font-family: OutfitExtraBold; color: black" :style="{
                'line-height':
                  $vuetify.breakpoint.name == 'xs'
                    ? '35px'
                    : $vuetify.breakpoint.name == 'sm'
                      ? '20px'
                      : $vuetify.breakpoint.name == 'md'
                        ? '20px'
                        : '50px',

                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '30px'
                    : $vuetify.breakpoint.name == 'sm'
                      ? '28px'
                      : $vuetify.breakpoint.name == 'md'
                        ? '25px'
                        : '40px',
              }">
                    Welcome to a world of possibilities <br>with Rubicon
                  </span>

                  <br />
                  <br />

                  <v-flex xs7 sm10 md10 lg8>
                    <span style="font-family: JostRegular; color: #000;" :style="{
                      'line-height':
                        $vuetify.breakpoint.name == 'xs' ? '20px' :
                          ($vuetify.breakpoint.name == 'sm' ? '10px' :
                            ($vuetify.breakpoint.name == 'md' ? '28px' : '30px')),
      
                      'font-size':
                        $vuetify.breakpoint.name == 'xs' ? '15px' :
                          ($vuetify.breakpoint.name == 'sm' ? '20px' :
                            ($vuetify.breakpoint.name == 'md' ? '22px' : '25px')),
                    }">
                    We are dedicated to empowering you with the confidence and skills necessary to achieve your dreams. Join us at Rubicon and embark on a journey of self-discovery and limitless potential.
      
                        </span>
                  </v-flex>
               

               

                  <v-layout wrap justify-start style="font-family: OutfitSemiBold">
                    <!-- Learning Chip -->
                    <v-flex xs12>
                      <v-layout wrap>
                        <v-flex xs12 sm3 md3 lg2 pt-5>
                          <v-chip :color="isArrowUp1 ? '#065e9c' : 'white'" style="border-radius: 5px; padding: 20px"
                            @click="toggleArrow1">
                            <span :class="{ 'blue-text': isArrowUp1 }">Learning
                              <v-icon :class="{ 'rotate-arrow': isArrowUp1 }">mdi-chevron-down</v-icon>
                            </span>
                          </v-chip>
                        </v-flex>

                        <!-- Training & Certification Chip -->
                        <v-flex xs12 sm9 md5 lg4 pt-5>
                          <v-chip :color="isArrowUp2 ? '#065e9c' : 'white'" style="border-radius: 5px; padding: 20px"
                            @click="toggleArrow2">
                            <span :class="{ 'blue-text': isArrowUp2 }">
                              Training & Certification
                              <v-icon :class="{ 'rotate-arrow': isArrowUp2 }">mdi-chevron-down</v-icon>
                            </span>
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <!-- Additional Chips for Learning (conditionally rendered) -->
                    <v-flex xs12 sm12 md12 lg9>
                      <v-layout wrap v-if="showLearningChips">
                        <v-flex pt-5 v-for="(chip, index) in extraLearningChips" :key="index">
                          <v-chip :color="chip.isArrowUp ? 'blue' : '#065e9c'" @click="toggleLearningChip(index)">
                            <span style="color: #fbf7ff;" :class="{ 'blue-text': chip.isArrowUp }"> {{ chip.name }}
                              <v-icon v-if="chip.subChips && chip.subChips.length"
                                :class="{ 'rotate-arrow': chip.isArrowUp }">mdi-chevron-down</v-icon>
                            </span></v-chip>
                          <v-layout wrap v-if="chip.showSubChips">
                            <v-flex xs12 pt-5 v-for="(subChip, subIndex) in chip.subChips" :key="subIndex">
                              <!-- Check if subChip is a string or an object containing 'name' and 'route' -->
                              <template v-if="typeof subChip === 'string'">
                                <v-chip color="white">{{ subChip }}</v-chip>
                              </template>
                              <template v-else>
                                <!-- If subChip is an object with name and route properties -->
                                <router-link :to="subChip.route">
                                  <v-chip color="blue"> <span style="color:white;cursor: pointer">{{
                                      subChip.name
                                      }}</span></v-chip>
                                </router-link>
                              </template>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <!-- Additional Chips for Training & Certification (conditionally rendered) -->
                    <v-flex xs12>
                      <v-layout wrap v-if="showTrainingChips">
                        <v-flex xs12>
                          <v-layout wrap v-if="showTrainingChips">
                            <v-flex pt-5 v-for="(chip, index) in extraTrainingChips" :key="index">
                              <router-link :to="chip.route">
                                <v-chip :color="chip.isArrowUp ? 'blue' : '#065e9c'" @click="toggleTrainingChip(index)">
                                  <span style="color:white"> {{ chip.name }}</span>
                                  <v-icon v-if="
                                chip.subChips && chip.subChips.length
                              " :class="{
                                  'rotate-arrow': chip.isArrowUp,
                                }">mdi-chevron-down</v-icon>
                                </v-chip>
                              </router-link>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>


          </v-layout>
        </div>
      </v-card>



      <v-card elevation="0" v-if="itemsCarousel.length>0" class="hidden-sm-and-up px-5 pt-5">
        <v-layout wrap justify-center style="background-color: #fbf7ff">
          <v-flex xs10 pt-5>
            <v-layout wrap justify-center>
              <v-flex xs12 sm12 md10 lg10 pt-0 pt-md-0 data-aos="zoom-in-up" data-aos-ease="ease"
                data-aos-duration="1500" data-aos-delay="500">
                <span style="font-family: OutfitExtraBold; color: black" :style="{
              'line-height':
                $vuetify.breakpoint.name == 'xs'
                  ? '35px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '20px'
                      : '50px',
  
              'font-size':
                $vuetify.breakpoint.name == 'xs'
                  ? '30px'
                  : $vuetify.breakpoint.name == 'sm'
                    ? '28px'
                    : $vuetify.breakpoint.name == 'md'
                      ? '25px'
                      : '40px',
            }">
                  Welcome to a world of possibilities with Rubicon
                </span>

                <br />
                <br />
                <span style="font-family: JostRegular; color: #000;" :style="{
              'line-height':
                $vuetify.breakpoint.name == 'xs' ? '20px' :
                  ($vuetify.breakpoint.name == 'sm' ? '10px' :
                    ($vuetify.breakpoint.name == 'md' ? '28px' : '30px')),
  
              'font-size':
                $vuetify.breakpoint.name == 'xs' ? '15px' :
                  ($vuetify.breakpoint.name == 'sm' ? '20px' :
                    ($vuetify.breakpoint.name == 'md' ? '22px' : '25px')),
            }">
                  We believe that every individual possesses unique talents, aspirations, and potential waiting to
                  be unlocked.
                </span>

                <br />

                <v-layout wrap justify-start style="font-family: OutfitSemiBold" py-4>
                  <!-- Learning Chip -->
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-chip :color="isArrowUp1 ? '#065e9c' : 'white'" style="border-radius: 5px; padding: 20px"
                          @click="toggleArrow1">
                          <span :class="{ 'blue-text': isArrowUp1 }">Learning
                            <v-icon :class="{ 'rotate-arrow': isArrowUp1 }">mdi-chevron-down</v-icon>
                          </span>
                        </v-chip>
                      </v-flex>

                       <!-- Additional Chips for Learning (conditionally rendered) -->
                  <v-flex xs12 sm12 md12 lg12>
                    <v-layout wrap v-if="showLearningChips">
                      <v-flex pt-5 v-for="(chip, index) in extraLearningChips" :key="index">
                        <v-chip :color="chip.isArrowUp ? 'blue' : '#065e9c'" @click="toggleLearningChip(index)">
                          <span style="color: #fbf7ff;" :class="{ 'blue-text': chip.isArrowUp }"> {{ chip.name }}
                            <v-icon v-if="chip.subChips && chip.subChips.length"
                              :class="{ 'rotate-arrow': chip.isArrowUp }">mdi-chevron-down</v-icon>
                          </span></v-chip>
                        <v-layout wrap v-if="chip.showSubChips">
                          <v-flex xs12 pt-5 v-for="(subChip, subIndex) in chip.subChips" :key="subIndex">
                            <!-- Check if subChip is a string or an object containing 'name' and 'route' -->
                            <template v-if="typeof subChip === 'string'">
                              <v-chip color="white">{{ subChip }}</v-chip>
                            </template>
                            <template v-else>
                              <!-- If subChip is an object with name and route properties -->
                              <router-link :to="subChip.route">
                                <v-chip color="blue"> <span style="color:white;cursor: pointer">{{
                                    subChip.name
                                    }}</span></v-chip>
                              </router-link>
                            </template>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                      <!-- Training & Certification Chip -->
                      <v-flex xs12 pt-2>
                        <v-chip :color="isArrowUp2 ? '#065e9c' : 'white'" style="border-radius: 5px; padding: 20px"
                          @click="toggleArrow2">
                          <span :class="{ 'blue-text': isArrowUp2 }">
                            Training & Certification
                            <v-icon :class="{ 'rotate-arrow': isArrowUp2 }">mdi-chevron-down</v-icon>
                          </span>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                 
                  <!-- Additional Chips for Training & Certification (conditionally rendered) -->
                  <v-flex xs12>
                    <v-layout wrap v-if="showTrainingChips">
                      <v-flex xs12>
                        <v-layout wrap v-if="showTrainingChips">
                          <v-flex pt-5 v-for="(chip, index) in extraTrainingChips" :key="index">
                            <router-link :to="chip.route">
                              <v-chip :color="chip.isArrowUp ? 'blue' : '#065e9c'" @click="toggleTrainingChip(index)">
                                <span style="color:white"> {{ chip.name }}</span>
                                <v-icon v-if="
                              chip.subChips && chip.subChips.length
                            " :class="{
                                'rotate-arrow': chip.isArrowUp,
                              }">mdi-chevron-down</v-icon>
                              </v-chip>
                            </router-link>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>


        </v-layout>

        <div class="carousel-container ">
          <OwlCarousel :nav="false" :dots="false" :loop="true" :autoplay="true" :autoplaySpeed="1000"
            :autoplayTimeout="5000" :animateOut="'fadeOut'" :responsive="owlResponsive">
            <template v-for="(list, l) in itemsCarousel">
              <v-layout wrap justify-center v-if="list" :key="l">
                <v-flex>
                  <v-img :max-height="$vuetify.breakpoint.name == 'xs'
                    ? '800px'
                    : $vuetify.breakpoint.name == 'sm'
                      ? '350px'
                      : $vuetify.breakpoint.name == 'md'
                        ? '100%'
                        : '100%'" :src="list.src" eager></v-img>
                </v-flex>
              </v-layout>
            </template>
          </OwlCarousel>

        </div>
      </v-card>





    </div>
    <!-- -------------------------------- -->
    <v-layout wrap justify-center pt-lg-16>
      <v-flex xs11 sm11 md11>
        <overSeas />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs11 sm11 md11>
        <AboutUs />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs11 sm11 md11>
        <OurValues />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs11 sm11 md11>
        <Courses />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center style="background-color: #fbf7ff">
      <v-flex xs11 sm11 md11>
        <WhyChoose />
      </v-flex>
    </v-layout>

  </div>
</template>
<script>
// import axios from "axios";
import AboutUs from "./aboutUs";
import OurValues from "./ourValues";
import Courses from "./courses";
import WhyChoose from "./whyChoose";
import overSeas from "./overSeas.vue";
export default {
  components: {
    AboutUs,
    OurValues,
    Courses,
    WhyChoose,
    overSeas,
  },
  data() {
    return {
      isArrowUp1: false,
      isArrowUp2: false,
      showLearningChips: false,
      showTrainingChips: false,
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 1, nav: false },
        1264: { items: 1, nav: false },
        1920: { items: 1, nav: false },
      },
      extraLearningChips: [
        {
          name: "Languages",
          isArrowUp: false,
          showSubChips: false,
          subChips: [
            { name: "Rubycon Carmel Course", route: "/carmelcourse" }, // Example route
          ],
        },
        {
          name: "Computer Courses",
          isArrowUp: false,
          showSubChips: false,
          subChips: [
            { name: "Rubycon Zen Course", route: "/zenintro" }, // Example route
          ],
        },
        { name: "Medical Courses", isArrowUp: false, showSubChips: false },
        {
          name: "Accounting Softwares",
          isArrowUp: false,
          showSubChips: false,
        },
      ],
      extraTrainingChips: [
        { name: "Health & Safety", isArrowUp: false, route: "/onlinecourse" },
        // Add more chips with their respective routes if needed
      ],
      itemsCarousel: [
        {
          src: require("./../../assets/images/ruby1.png"),
        },

        {
          src: require("./../../assets/images/ruby2.png"),
        },
        {
          src: require("./../../assets/images/ruby3.png"),
        },
      ],
    };
  },

  methods: {
    toggleArrow1() {
      this.isArrowUp1 = !this.isArrowUp1;
      this.showLearningChips = !this.showLearningChips;

      // Reset Training chips state when Learning is toggled
      if (this.showLearningChips) {
        this.isArrowUp2 = false;
        this.showTrainingChips = false;
        this.resetChipsState(this.extraTrainingChips);
      }
    },
    toggleArrow2() {
      this.isArrowUp2 = !this.isArrowUp2;
      this.showTrainingChips = !this.showTrainingChips;

      // Reset Learning chips state when Training is toggled
      if (this.showTrainingChips) {
        this.isArrowUp1 = false;
        this.showLearningChips = false;
        this.resetChipsState(this.extraLearningChips);
      }
    },
    toggleLearningChip(index) {
      this.extraLearningChips.forEach((chip, i) => {
        if (i === index) {
          chip.isArrowUp = !chip.isArrowUp;
          chip.showSubChips = !chip.showSubChips;
        } else {
          chip.isArrowUp = false;
          chip.showSubChips = false;
        }
      });
    },
    toggleTrainingChip(index) {
      this.extraTrainingChips.forEach((chip, i) => {
        if (i === index) {
          chip.isArrowUp = !chip.isArrowUp;
          chip.showSubChips = !chip.showSubChips;
        } else {
          chip.isArrowUp = false;
          chip.showSubChips = false;
        }
      });
    },
    resetChipsState(chips) {
      chips.forEach(chip => {
        chip.isArrowUp = false;
        chip.showSubChips = false;
      });
    }
  },
};
</script>

<style scoped>

.carousel-container {
  position: relative;
}

.fixed-overlay-content {
  position: absolute;
  top:25%;
  margin-left:0%;
  
  color: white;

  z-index: 1;
}


.fixed-overlay1-content {
  position: absolute;
  top:15%;
  margin-left:0%;
 
  color: white;

  z-index: 50;
}

.owl-carousel .owl-item {
  transition: opacity 1s ease-in-out;
}

.owl-carousel .owl-item.fadeOut {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.blue-text {
  color: white;
  /* Change this to the color you desire */
}

.rotate-arrow {
  transform: rotate(180deg);
}


</style>