var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-lg-5":"","py-16":""}},[_c('v-flex',{attrs:{"xs12":"","sm7":"","md7":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"JostBold","font-size":"25px"}},[_vm._v("ABOUT US")])]),_c('v-flex',{attrs:{"xs2":"","text-center":""}},[_c('v-progress-linear',{attrs:{"value":"100","height":"2","color":"primary"}})],1),_c('v-flex',{attrs:{"x12":"","pt-2":""}},[_c('span',{staticStyle:{"font-family":"OutfitExtraBold","color":"#434343","font-size":"35px"}},[_vm._v("Welcome to our Online Learning Center")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":"","text-justify":""}},[_c('span',{staticStyle:{"font-family":"JostRegular","font-size":"16px","text-align":"justify"}},[_vm._v("Rubicon Career Counselling Centre is your trusted partner on the journey towards fulfilling your professional career goals. Located in the heart of Kottayam District, our centre provides comprehensive and personalized career guidance and support services to students, skilled or unskilled, professionals, and individuals seeking to chart their course in the dynamic world of work.")])])],1),_c('v-flex',{attrs:{"xs12":"","sm5":"","md5":"","pl-lg-10":"","data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_c('v-img',{staticStyle:{"margin-top":"30px","width":"450px","height":"auto"},attrs:{"src":require("./../../assets/images/about.png")}})],1),_c('v-layout',{attrs:{"wrap":"","pt-6":""}},_vm._l((_vm.items),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":"","md4":"","pa-2":"","pb-0":"","data-aos":"slide-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500","text-justify":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{style:({
              background: hover ? '#51A6FF' : 'white',
            }),attrs:{"height":_vm.$vuetify.breakpoint.name == 'xs'
                ? '100%'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '350px'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '350px'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '300px'
                : '300px'}},[_c('v-layout',{attrs:{"wrap":"","pa-5":"","py-10":"","py-sm-5":"","py-md-5":"","py-lg-10":"","py-xl-10":"","pa-sm-5":"","pa-md-5":"","pa-lg-5":"","pa-xl-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{attrs:{"size":"40","color":hover ? 'white' : '#51A6FF'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-flex',{attrs:{"xs12":"","pt-3":""}},[_c('span',{style:({
                    'font-family': 'OutfitBold',
                    'font-size': '20px',
                    color: hover ? 'white' : 'black',
                  })},[_vm._v(_vm._s(item.name))])]),_c('v-flex',{attrs:{"xs12":"","pt-3":""}},[_c('span',{style:({
                  'font-family': 'JostRegular',
                  'text-align': 'justify',
                  color: hover ? 'white' : 'black',
                })},[_vm._v(_vm._s(item.content))])])],1)],1)]}}],null,true)})],1)}),1),_c('v-layout',{attrs:{"wrap":"","pt-10":"","data-aos":"flip-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"100"}},[_c('v-flex',{attrs:{"xs12":"","pa-lg-3":""}},[_c('v-card',{style:({
              background:
                'linear-gradient(356deg, #51A6FF 0%, #5995EE 100%)',
            }),attrs:{"rounded":"xl","outlined":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[_c('v-flex',{attrs:{"xs11":"","sm11":"","md10":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"OutfitBold","color":"white"},style:({
                   
                    'font-size': _vm.$vuetify.breakpoint.smAndDown ? '30px' : '40px'
                  })},[_vm._v("We'll help you learn "),_c('br'),_vm._v(" what you like")])]),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"JostSemiBold","color":"white","font-size":"35px"}},[_vm._v("VISION")]),_c('br'),_c('span',{staticStyle:{"font-family":"JostRegular","color":"white","font-size":"16px"}},[_vm._v("Our vision is to be India’s recognised source of independent careers information provider for Indians of all ages and stages of their career.")])]),_c('v-flex',{attrs:{"xs12":"","pt-5":""}},[_c('span',{staticStyle:{"font-family":"JostSemiBold","color":"white","font-size":"35px"}},[_vm._v("MISSION")]),_c('br'),_c('span',{staticStyle:{"font-family":"JostRegular","color":"white","font-size":"16px"}},[_vm._v("Our mission is to empower the diverse Indian resource pool to develop their full potential and enhance their personal excellence and marketability by providing strategic career guidance and reliable information to connect with opportunities worldwide.")])])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }