var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","pa-lg-10":"","py-10":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"JostBold","font-size":"25px"}},[_vm._v("WHY CHOOSE US")])]),_c('v-flex',{attrs:{"xs2":"","sm2":"","md2":"","text-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-progress-linear',{attrs:{"value":"100","height":"2","color":"#1B6DC1"}})],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pt-5":""}},_vm._l((_vm.items),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm6":"","md4":"","pa-2":"","pa-xs-2":"","pa-sm-2":"","pa-md-5":"","pa-lg-5":"","pa-xl-5":""}},[_c('v-card',{attrs:{"height":_vm.$vuetify.breakpoint.name == 'xs'
                ? '100%'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '300px'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '300px'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '260px'
                : '260px'}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-5":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{attrs:{"size":"40","color":_vm.hover ? 'white' : '#1B6DC1'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-flex',{attrs:{"xs12":"","pt-3":""}},[_c('span',{staticStyle:{"font-family":"OutfitBold","font-size":"20px","color":"black"}},[_vm._v(_vm._s(item.name))])]),_c('v-flex',{attrs:{"xs12":"","pt-3":"","text-justify":""}},[_c('span',{staticStyle:{"font-family":"JostRegular","color":"black","text-align":"justify"}},[_vm._v(_vm._s(item.content))])])],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }