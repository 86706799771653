<template>
  <div>
    <v-layout wrap pa-lg-10 py-10>
      <v-flex xs12>
        <span style="font-family: JostBold; font-size: 25px"
          >WHY CHOOSE US</span
        >
      </v-flex>
      <v-flex xs2 sm2 md2 text-center>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear
              value="100"
              height="2"
              color="#1B6DC1"
            ></v-progress-linear>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout wrap justify-start pt-5>
        <v-flex xs12 sm6 md4 pa-2 pa-xs-2 pa-sm-2 pa-md-5 pa-lg-5 pa-xl-5 v-for="(item, i) in items" :key="i">
          <v-card    :height="
                $vuetify.breakpoint.name == 'xs'
                  ? '100%'
                  : $vuetify.breakpoint.name == 'sm'
                  ? '300px'
                  : $vuetify.breakpoint.name == 'md'
                  ? '300px'
                  : $vuetify.breakpoint.name == 'lg'
                  ? '260px'
                  : '260px'
              ">
            <v-layout wrap justify-center pa-5>
              <v-flex xs12 >
                <v-icon size="40" :color="hover ? 'white' : '#1B6DC1'">{{
                  item.icon
                }}</v-icon>
              </v-flex>
              <v-flex xs12 pt-3>
                <span
                  style="font-family: OutfitBold; font-size: 20px; color: black"
                  >{{ item.name }}</span
                >
              </v-flex>
              <v-flex xs12 pt-3 text-justify>
                <span style="font-family: JostRegular; color: black; text-align:justify">{{
                  item.content
                }}</span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      items: [
        {
          icon: "mdi-lightbulb-variant",
          name: "Expertise",
          content:
            "We have with us the best personnel in the trade who are equipped with up to date knowledge and years of experience who finds joy in sharing the best of their knowledge",
        },
        {
          icon: "mdi-handshake",
          name: "Value",
          content:
            "We provide the best services at the most reasonable value thanks to the most efficient methods for resource utilization and productive work environment.",
        },
        {
          icon: "mdi-hand-heart",
          name: "Simplicity",
          content:
            "With years of expertise and in depth knowledge in our fields, we have developed the most simple practices that delivers the best output with the least effort.",
        },
        {
          icon: "mdi-ballot",
          name: "Sophistication",
          content:
            "We make the best use of technology incorporation into our service delivery and management providing for maximum accessibility to the best of our potentials.",
        },
        {
          icon: "mdi-piggy-bank",
          name: "Shared vision",
          content:
            "Standing on our clients' feet, we empathise for them and identifies their dreams as our own and work restless until the dreams are achieved.",
        },
        {
          icon: "mdi-account-group",
          name: "Supportive Community",
          content:
            "We create a nurturing environment where students and educators support each other, fostering collaboration and shared success.",
        },
      ],
    };
  },
};
</script>