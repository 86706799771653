<template>
  <div>
    <v-layout wrap  pa-lg-10  >
      <v-flex xs12>
        <span style="font-family: JostBold; font-size: 25px">OUR COURSES</span>
      </v-flex>
      <v-flex xs2 sm2 md2 text-center>
        <v-layout wrap>
          <v-flex xs12 lg10>
            <v-progress-linear
              value="100"
              height="2"
              color="#1B6DC1"
            ></v-progress-linear>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-layout wrap justify-start  align-self-center pt-10>
        <v-flex
          xs12
          sm6
          md5
          lg4
          pa-2 pa-xs-2 pa-sm-2 pa-md-5 pa-lg-5 pa-xl-5
          v-for="(item, i) in items"
          :key="i"
          data-aos="slide-up"
          data-aos-ease="ease"
          data-aos-duration="1500"
          data-aos-delay="500"
          style="display: flex; justify-content: center;"
        >
          <v-card outlined style="width: 100%;">
            <v-layout wrap justify-center>
              <v-flex xs12 text-left>
                <v-img :src="item.img"></v-img>
              </v-flex>
              <v-flex xs12 pt-3 px-4>
                <span
                  style="font-family: OutfitBold; font-size: 20px; color: black"
                  >{{ item.name }}</span
                >
              </v-flex>
              <v-flex xs12 pt-3 px-4 pb-5>
                <span style="font-family: JostRegular; color: black">{{
                  item.content
                }}</span>
              </v-flex>

        
              <v-flex xs3 sm3 md3 lg1 >
                <v-img 
                      width="22px"
                      height="20px"
                      src="./../../assets/icons/courseicon.png">
                </v-img>
                
              </v-flex>
              <v-flex xs5  pb-5>
               
                <span  style="font-family:JostSemiBold">{{
                  item.count
                }}</span>
              </v-flex>


              <v-flex xs5 pl-lg-5  pb-5 align-self-center>
                <router-link :to="item.route" style="text-decoration: none">
                <v-btn class="ml-3 px-8" 
                      color="primary"
                      
                      small>
                       
                        
                            <span style="color: white;">View</span>
                         
                        
                      </v-btn>
                    </router-link>
             </v-flex>


             
         

            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        // {
        //   img: require("./../../assets/images/oet.png"),
        //   name: "Occupational English Test (OET)",
        //   content:
        //     "Global supply chain management, international shipping, and trade compliance.",
        // },
        {
          img: require("./../../assets/images/ielts.png"),
          name: "Rubicon Carmel Course",
          count:"1 Course",
          content:
            "Gain fluency and cultural insight in German Language, and excel in IELTS with expert guidance",
            route: "/carmelcourse",
        },
        {
          img: require("./../../assets/images/cbt.png"),
          name: "Rubicon Zen Course",
          count:"4 Courses",
          content:
            "Explore new skills and passions with our diverse course offerings.",
            
            route: "/zenintro",
        },
        {
          img: require("./../../assets/images/onlinecourse.jpg"),
          name: "Rubicon Online Course",
          count:"5 Courses",
          content:
            "Explore new skills and passions with our diverse course offerings.",
            
            route: "/onlinecourse",
        },
        // {
        //   img: require("./../../assets/images/ger.png"),
        //   name: "Germen Language",
        //   count:"4 Courses",
        //   content:
        //     "Global supply chain management, international shipping, and trade compliance.",
        // },
        // {
        //   img: require("./../../assets/images/ce.png"),
        //   name: "Communicative English",
        //   content:
        //     "Global supply chain management, international shipping, and trade compliance.",
        // },
        // {
        //   img: require("./../../assets/images/pro.png"),
        //   name: "Prometric",
        //   content:
        //     "Global supply chain management, international shipping, and trade compliance.",
        // },
      ],
    };
  },
};
</script>