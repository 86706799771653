<template>
  <div>
    <v-layout wrap justify-center pa-lg-5 py-16>
      <v-flex xs12 sm7 md7  >
        <v-flex xs12>
          <span style="font-family: JostBold; font-size: 25px">ABOUT US</span>
        </v-flex>
        <v-flex xs2 text-center>
          <v-progress-linear
            value="100"
            height="2"
            color="primary"
          ></v-progress-linear>
        </v-flex>
        <v-flex x12 pt-2>
          <span
            style="
              font-family: OutfitExtraBold;
              color: #434343;
              font-size: 35px;
            "
            >Welcome to our Online Learning Center</span
          >
        </v-flex>
        <v-flex xs12 pt-2 text-justify>
          <span style="font-family: JostRegular; font-size: 16px; text-align: justify;"
            >Rubicon Career Counselling Centre is your trusted partner on the
            journey towards fulfilling your professional career goals. Located
            in the heart of Kottayam District, our centre provides comprehensive
            and personalized career guidance and support services to students,
            skilled or unskilled, professionals, and individuals seeking to
            chart their course in the dynamic world of work.</span
          >
        </v-flex>
        <!-- <v-flex xs12 pt-10>
          <v-card outlined>
            <v-layout wrap pa-5>
              <v-flex xs12 sm3 md3 lg3>
                <span
                  style="
                    font-family: JostExtraBold;
                    font-size: 25px;
                    color: #1B6DC1;
                  "
                >
                  60K+
                </span>
                <br />
                <span style="font-family: JostMedium; font-size: 20px"
                  >Success Stories</span
                >
              </v-flex>
              <v-flex xs1>
                &nbsp;&nbsp;&nbsp; <v-divider vertical></v-divider>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3>
                <span
                  style="
                    font-family: JostExtraBold;
                    font-size: 25px;
                    color:#1B6DC1;
                  "
                >
                  100K+
                </span>
                <br />
                <span style="font-family: JostMedium; font-size: 20px"
                  > Happy Students</span
                >
              </v-flex>
              <v-flex xs1>
                &nbsp;&nbsp;&nbsp;<v-divider vertical></v-divider>
              </v-flex>
              <v-flex xs12 sm3 md3 lg4>
                <span
                  style="
                    font-family: JostExtraBold;
                    font-size: 25px;
                    color: #1B6DC1;
                  "
                >
                  100 %
                </span>
                <br />
                <span style="font-family: JostMedium; font-size: 20px"
                  >Satisfaction Rate</span
                >
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex> -->
      </v-flex>
      <v-flex
        
        xs12
        sm5
        md5
       pl-lg-10
        data-aos="zoom-in-up"
        data-aos-ease="ease"
        data-aos-duration="1500"
        data-aos-delay="500"
      >
        <v-img src="./../../assets/images/about.png" style=" margin-top: 30px; width: 450px; height: auto;"></v-img>
      </v-flex>
      <v-layout wrap pt-6 >
        <v-flex
          xs12
          sm6
          md4
          pa-2 pb-0
          v-for="(item, i) in items"
          :key="i"
          data-aos="slide-up"
          data-aos-ease="ease"
          data-aos-duration="1500"
          data-aos-delay="500"
          text-justify
        >
          <v-hover v-slot="{ hover }">
            <v-card
              :height="
                $vuetify.breakpoint.name == 'xs'
                  ? '100%'
                  : $vuetify.breakpoint.name == 'sm'
                  ? '350px'
                  : $vuetify.breakpoint.name == 'md'
                  ? '350px'
                  : $vuetify.breakpoint.name == 'lg'
                  ? '300px'
                  : '300px'
              "
              :style="{
                background: hover ? '#51A6FF' : 'white',
              }"
            >
              <v-layout
                wrap
                pa-5
                py-10
                py-sm-5
                py-md-5
                py-lg-10
                py-xl-10
                pa-sm-5
                pa-md-5
                pa-lg-5
                pa-xl-5
              >
                <v-flex xs12>
                  <v-icon size="40" :color="hover ? 'white' : '#51A6FF'">{{
                    item.icon
                  }}</v-icon>
                </v-flex>
                <v-flex xs12 pt-3>
                  <span
                    :style="{
                      'font-family': 'OutfitBold',
                      'font-size': '20px',
                      color: hover ? 'white' : 'black',
                    }"
                    >{{ item.name }}</span
                  >
                </v-flex>
                <v-flex xs12 pt-3>
                  <span 
                  :style="{
                    'font-family': 'JostRegular',
                    'text-align': 'justify',
                    color: hover ? 'white' : 'black',
                  }"
                >{{ item.content }}</span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-hover>
        </v-flex>
      </v-layout>
      <v-layout
        wrap
        pt-10
        data-aos="flip-up"
        data-aos-ease="ease"
        data-aos-duration="1500"
        data-aos-delay="100"
      >
        <v-flex xs12 pa-lg-3>
          <v-card
            rounded="xl"
            outlined
            :style="{
                background:
                  'linear-gradient(356deg, #51A6FF 0%, #5995EE 100%)',
              }"
          >
            <v-layout wrap justify-center py-10>
              <v-flex xs11 sm11 md10>
                <v-layout wrap>
                  <v-flex xs12 sm6 align-self-center>
                    <span :style="{
                     
                      'font-size': $vuetify.breakpoint.smAndDown ? '30px' : '40px'
                    }"
                      style="
                        font-family: OutfitBold;
                        color: white;
                    
                        
                      "
                      >We'll help you learn <br />
                      what you like</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: JostSemiBold;
                            color: white;
                            font-size: 35px;
                          "
                          >VISION</span>
                          <!-- <v-flex xs5 sm5 md4 lg3 pr-lg-8 text-center>
                            <v-progress-linear
                              value="100"
                              height="3"
                              color="white"
                            ></v-progress-linear>
                          </v-flex> -->
                        <br />
                      
                        <span
                          style="
                            font-family: JostRegular;
                            color: white;
                            font-size: 16px;
                          "
                          >Our vision is to be India’s recognised source of
                          independent careers information provider for Indians
                          of all ages and stages of their career.</span
                        ></v-flex
                      >
                      <v-flex xs12 pt-5>
                        <span
                          style="
                            font-family: JostSemiBold;
                            color: white;
                            font-size: 35px;
                          "
                          >MISSION</span>
                          <!-- <v-flex xs5 sm5 md4 lg3 pr-lg-5 text-center>
                            <v-progress-linear
                              value="100"
                              height="3"
                              color="white"
                            ></v-progress-linear>
                          </v-flex> -->
                        <br />
                      
                        <span
                          style="
                            font-family: JostRegular;
                            color: white;
                            font-size: 16px;
                          "
                          >Our mission is to empower the diverse Indian resource
                          pool to develop their full potential and enhance their
                          personal excellence and marketability by providing
                          strategic career guidance and reliable information to
                          connect with opportunities worldwide.</span
                        ></v-flex
                      >
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          icon: "mdi-store",
          name: "Institution",
          content:
            "Rubicon Institutions primarily provide training programs for major language assessment tests such as IELTS, OET, CBT, etc., through both online and offline modes across more than 10 branches in India and abroad.",
        },
        // {
        //   icon: "mdi-web",
        //   name: "Overseas Consultancy",
        //   content:
        //     "Rubicon Overseas Consultancy assists students in discovering the most suitable courses abroad,guiding them toward establishing a settled life and career in their desired countries of residence.",
        // },
        {
          icon: "mdi-school",
          name: "Entrance Academy",
          content:
            "Rubicon was founded with a singular mission: to support students in preparing for major competitive entrance examinations like NEET, KEAM, JEE, and more. Our aim is to equip them with the tools and knowledge needed to qualify for admission.",
        },
        {
          icon: "mdi-account-switch",
          name: "Distance Education",
          content:
            "We deliver the full spectrum of possibilities that Distance Education offers. Whether it's accessing desired courses through remote learning or completing unfinished education via credit transfer schemes.",
        },
      ],
    };
  },
};
</script>